import { first } from 'lodash-es'
import router from '../router'
import { opsLogApplicationIdentifier } from '../shared/system-state'
import { submitOpsEvent } from './ops-log-api'

// Record app load time in ops log
const observer = new PerformanceObserver(list => {
  list.getEntries().forEach(entry => {
    const duration = Math.round(entry.domContentLoadedEventEnd - entry.startTime)
    const event = {
      severity: duration > 2_000 ? 'warning' : 'info',
      activity: 'Application',
      identifier: opsLogApplicationIdentifier,
      name: 'load',
      data: { duration }
    }
    submitOpsEvent(event)
    observer.disconnect()
  })
})
observer.observe({ type: 'navigation', buffered: true })

export const genericOpsEventBase = (defaultActivity = 'unspecified') => {
  const route = router.currentRoute.value
  const activity = route.name ?? defaultActivity
  const paramsCount = Object.keys(route.params).length
  const identifier =
    paramsCount === 0
      ? null
      : paramsCount === 1
        ? first(Object.values(route.params))
        : JSON.stringify(route.params)
  return { activity, identifier }
}

export const genericRouteOpsEventData = () => {
  const route = router.currentRoute.value
  const { fullPath, params, path, query } = route
  return { route: { fullPath, params, path, query } }
}
